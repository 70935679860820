import axios from 'axios'

const userModule = {
  namespaced: true,
  state() {
    return {
      allUsers: [],
      error: null,
      lastUser: {},
      apiUrl: '/api/users',
    }
  },
  getters: {
    allUsers(state) {
      return state.allUsers
    },
    error: (state) => {
      return state.error
    },
    lastUser: (state) => {
      return state.lastUser
    },
  },
  mutations: {
    allUsers: (state, users) => {
      state.allUsers = users
    },
    error: (state, message) => {
      state.error = message
    },
    lastUser: (state, user) => {
      state.lastUser = user
    },
  },
  actions: {
    async fetchUsers({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allUsers', response.data.users)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allUsers', [])
        })
    },
    async storeUser({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getUser({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastUser', response.data.user)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastUser', {})
        })
    },
    async updateUser({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteUser({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default userModule

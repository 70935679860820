import { createStore } from 'vuex'
import axios from 'axios'
import sectionModule from './section'
import brandModule from './brand'
import brandSectionModule from './brandSection'
import productCategoryModule from './productCategory'
import productSubcategoryModule from './productSubcategory'
import productModule from './product'
import productVariantModule from './productVariant'
import recipeCategoryModule from './recipeCategory'
import recipeModule from './recipe'
import releaseModule from './release'
import newsModule from './news'
import releaseInformationModule from './releaseInformation'
import userModule from './user'
import bannerModule from './banner'
import kidAreaModule from './kidArea'
import ethicCodeModule from './ethicCode'
import financialStatementModule from './financialStatement'
import jobOpportunityModule from './jobOpportunity'
import languageModule from './language'
import productCatalogModule from './productCatalog'
import externalScriptModule from './externalScript'
import newsletterModule from './newsletter'
import ameliaPascoaModule from './ameliaPascoa'
import sacModule from './sac'
import passwordResetModule from './passwordReset'
import packageModule from './package'
import nutritionalInfoModule from './nutritionalInfo'
import productBlockModule from './productBlock'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    user: null,
    refreshToken: null,
    token_type: null,
    accessToken: localStorage.getItem('accessToken') || null,
    loggingIn: false,
    loginError: null,
    imagesBaseUrl: process.env.VUE_APP_BASE_REQUEST_URL + '/storage/',
  },
  getters: {
    user: (state) => {
      return state.user
    },
    accessToken: (state) => {
      return state.accessToken
    },
    refreshToken: (state) => {
      return state.refreshToken
    },
    token_type: (state) => {
      return state.token_type
    },
    loginError: (state) => {
      return state.loginError
    },
    imagesBaseUrl: (state) => {
      return state.imagesBaseUrl
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    user: (state, user) => {
      state.user = user
    },
    refreshToken: (state, token) => {
      state.refreshToken = token
    },
    token_type: (state, type) => {
      state.token_type = type
    },
    loginStart: (state) => (state.loggingIn = true),
    loginStop: (state, errorMessage) => {
      state.loggingIn = false
      state.loginError = errorMessage
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
    },
  },
  actions: {
    user: (context, user) => {
      context.commit('user', user)
    },
    refreshToken: (context, token) => {
      context.commit('refreshToken', token)
    },
    token_type: (context, type) => {
      context.commit('token_type', type)
    },
    async doLogin({ commit }, loginData) {
      commit('loginStart')
      var data = {
        grant_type: process.env.VUE_APP_GRANT_TYPE,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        username: loginData.username,
        password: loginData.password,
        scope: process.env.VUE_APP_SCOPE,
      }
      await axios
        .post('/oauth/token', {
          ...data,
        })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.access_token)
          commit('loginStop', null)
          commit('updateAccessToken', response.data.access_token)
        })
        .catch((error) => {
          commit('loginStop', error.response.data.error_description)
          commit('updateAccessToken', null)
        })
    },
    async doLogout({ commit }) {
      localStorage.removeItem('accessToken')
      commit('updateAccessToken', null)
      commit('refreshToken', null)
    },
  },
  modules: {
    sectionModule: sectionModule,
    brandModule: brandModule,
    brandSectionModule: brandSectionModule,
    productCategoryModule: productCategoryModule,
    productSubcategoryModule: productSubcategoryModule,
    productModule: productModule,
    productVariantModule: productVariantModule,
    recipeCategoryModule: recipeCategoryModule,
    recipeModule: recipeModule,
    releaseModule: releaseModule,
    newsModule: newsModule,
    releaseInformationModule: releaseInformationModule,
    userModule: userModule,
    bannerModule: bannerModule,
    kidAreaModule: kidAreaModule,
    ethicCodeModule: ethicCodeModule,
    financialStatementModule: financialStatementModule,
    jobOpportunityModule: jobOpportunityModule,
    languageModule: languageModule,
    productCatalogModule: productCatalogModule,
    externalScriptModule: externalScriptModule,
    newsletterModule: newsletterModule,
    ameliaPascoaModule: ameliaPascoaModule,
    sacModule: sacModule,
    passwordResetModule: passwordResetModule,
    packageModule: packageModule,
    nutritionalInfoModule: nutritionalInfoModule,
    productBlockModule: productBlockModule,
  },
})

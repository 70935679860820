import axios from 'axios'

const bannerModule = {
  namespaced: true,
  state() {
    return {
      allBanners: [],
      error: null,
      lastBanner: {},
      apiUrl: '/api/banners',
    }
  },
  getters: {
    allBanners(state) {
      return state.allBanners
    },
    error: (state) => {
      return state.error
    },
    lastBanner: (state) => {
      return state.lastBanner
    },
  },
  mutations: {
    allBanners: (state, banners) => {
      state.allBanners = banners
    },
    error: (state, message) => {
      state.error = message
    },
    lastBanner: (state, banner) => {
      state.lastBanner = banner
    },
  },
  actions: {
    async fetchBanners({ state, commit, rootState }) {
      commit('error', null)
      commit('allBanners', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allBanners', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allBanners', [])
        })
    },
    async storeBanner({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getBanner({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastBanner', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastBanner', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastBanner', {})
        })
    },
    async updateBanner({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteBanner({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default bannerModule

import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/dashboard',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    beforeEnter: () => {
      if (!store.getters['accessToken']) {
        return '/login'
      }
      return true
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/usuarios',
        name: 'Usuários',
        component: () => import('@/views/users/Users'),
      },
      {
        path: '/marcas',
        name: 'Marcas',
        component: () => import('@/views/brands/Brands'),
      },
      {
        path: '/marcas/criar',
        name: 'Criar Marca',
        component: () => import('@/views/brands/CreateBrand'),
      },
      {
        path: '/marcas/:brandId',
        name: 'Editar Marca',
        component: () => import('@/views/brands/EditBrand'),
      },
      {
        path: '/categorias-de-produto',
        name: 'Categorias de Produtos',
        component: () => import('@/views/productCategories/ProductCategories'),
      },
      {
        path: '/categorias-de-produto/criar',
        name: 'Criar Categoria de Produto',
        component: () =>
          import('@/views/productCategories/CreateProductCategory'),
      },
      {
        path: '/categorias-de-produto/:categoryId',
        name: 'Editar Categoria de Produto',
        component: () =>
          import('@/views/productCategories/EditProductCategory'),
      },
      {
        path: '/subcategorias-de-produto',
        name: 'Subcategorias de Produtos',
        component: () =>
          import('@/views/productSubcategories/ProductSubcategories'),
      },
      {
        path: '/subcategorias-de-produto/criar',
        name: 'Criar Subcategoria de Produto',
        component: () =>
          import('@/views/productSubcategories/CreateProductSubcategory'),
      },
      {
        path: '/subcategorias-de-produto/:subcategoryId',
        name: 'Editar Subcategoria de Produto',
        component: () =>
          import('@/views/productSubcategories/EditProductSubcategory'),
      },
      {
        path: '/produtos',
        name: 'Produtos',
        component: () => import('@/views/products/Products'),
      },
      {
        path: '/produtos/criar',
        name: 'Criar Produto',
        component: () => import('@/views/products/CreateProduct'),
      },
      {
        path: '/produtos/:productId',
        name: 'Editar Produto',
        component: () => import('@/views/products/EditProduct'),
      },
      {
        path: '/variantes',
        name: 'Variantes de Produtos',
        component: () => import('@/views/variants/Variants'),
      },
      {
        path: '/variantes/criar',
        name: 'Criar Variante de Produto',
        component: () => import('@/views/variants/CreateVariant'),
      },
      {
        path: '/variantes/:variantId',
        name: 'Editar Variante de Produto',
        component: () => import('@/views/variants/EditVariant'),
      },
      {
        path: '/blocos-de-produtos',
        name: 'Blocos de Produtos',
        component: () => import('@/views/productBlocks/ProductBlocks'),
      },
      {
        path: '/blocos-de-produtos/criar',
        name: 'Criar Bloco de Produto',
        component: () => import('@/views/productBlocks/CreateProductBlock'),
      },
      {
        path: '/blocos-de-produtos/:blockId',
        name: 'Editar Bloco de Produto',
        component: () => import('@/views/productBlocks/EditProductBlock'),
      },
      {
        path: '/receitas',
        name: 'Receitas',
        component: () => import('@/views/recipes/Recipes'),
      },
      {
        path: '/receitas/criar',
        name: 'Criar Receita',
        component: () => import('@/views/recipes/CreateRecipe'),
      },
      {
        path: '/receitas/:recipeId',
        name: 'Editar Receita',
        component: () => import('@/views/recipes/EditRecipe'),
      },
      {
        path: '/releases',
        name: 'Releases',
        component: () => import('@/views/releases/Releases'),
      },
      {
        path: '/releases/criar',
        name: 'Criar Release',
        component: () => import('@/views/releases/CreateRelease'),
      },
      {
        path: '/releases/:releaseId',
        name: 'Editar Release',
        component: () => import('@/views/releases/EditRelease'),
      },
      {
        path: '/noticias',
        name: 'Notícias',
        component: () => import('@/views/news/News'),
      },
      {
        path: '/release-informacoes',
        name: 'Release informações',
        component: () =>
          import('@/views/releaseInformation/ReleaseInformation'),
      },
      {
        path: '/release-informacoes/criar',
        name: 'Criar Release Informações',
        component: () =>
          import('@/views/releaseInformation/CreateReleaseInformation'),
      },
      {
        path: '/release-informacoes/:releaseInformationId',
        name: 'Editar Release Informações',
        component: () =>
          import('@/views/releaseInformation/EditReleaseInformation'),
      },
      {
        path: '/noticias/criar',
        name: 'Criar Notícia',
        component: () => import('@/views/news/CreateNews'),
      },
      {
        path: '/noticias/:newsId',
        name: 'Editar Notícia',
        component: () => import('@/views/news/EditNews'),
      },
      {
        path: '/banners',
        name: 'Banners',
        component: () => import('@/views/banners/Banners'),
      },
      {
        path: '/banners/criar',
        name: 'Criar Banner',
        component: () => import('@/views/banners/CreateBanner'),
      },
      {
        path: '/banners/:bannerId',
        name: 'Editar Banner',
        component: () => import('@/views/banners/EditBanner'),
      },
      {
        path: '/area-kids',
        name: 'Área Kids',
        component: () => import('@/views/kidAreas/KidAreas'),
      },
      {
        path: '/area-kids/criar',
        name: 'Criar Área Kids',
        component: () => import('@/views/kidAreas/CreateKidArea'),
      },
      {
        path: '/area-kids/:areaId',
        name: 'Editar Área Kids',
        component: () => import('@/views/kidAreas/EditKidArea'),
      },
      {
        path: '/codigos-etica',
        name: 'Códigos de Ética',
        component: () => import('@/views/ethicCodes/EthicCodes'),
      },
      {
        path: '/codigos-etica/criar',
        name: 'Criar Código de Ética',
        component: () => import('@/views/ethicCodes/CreateEthicCode'),
      },
      {
        path: '/codigos-etica/:codeId',
        name: 'Editar Código de Ética',
        component: () => import('@/views/ethicCodes/EditEthicCode'),
      },
      {
        path: '/demonstracoes-financeiras',
        name: 'Demonstrações Financeiras',
        component: () =>
          import('@/views/financialStatements/FinancialStatements'),
      },
      {
        path: '/demonstracoes-financeiras/criar',
        name: 'Criar Demonstração Financeira',
        component: () =>
          import('@/views/financialStatements/CreateFinancialStatement'),
      },
      {
        path: '/demonstracoes-financeiras/:statementId',
        name: 'Editar Demonstração Financeira',
        component: () =>
          import('@/views/financialStatements/EditFinancialStatement'),
      },
      {
        path: '/vagas',
        name: 'Vagas',
        component: () => import('@/views/jobOpportunities/JobOpportunities'),
      },
      {
        path: '/vagas/criar',
        name: 'Criar Vaga',
        component: () =>
          import('@/views/jobOpportunities/CreateJobOpportunity'),
      },
      {
        path: '/vagas/:opportunityId',
        name: 'Editar Vaga',
        component: () => import('@/views/jobOpportunities/EditJobOpportunity'),
      },
      {
        path: '/catalogos-produtos',
        name: 'Catálogos de Produtos',
        component: () => import('@/views/productCatalogs/ProductCatalogs'),
      },
      {
        path: '/catalogos-produtos/criar',
        name: 'Criar Catálogo de Produtos',
        component: () => import('@/views/productCatalogs/CreateProductCatalog'),
      },
      {
        path: '/catalogos-produtos/:catalogId',
        name: 'Editar Catálogo de Produtos',
        component: () => import('@/views/productCatalogs/EditProductCatalog'),
      },
      {
        path: '/scripts-externos',
        name: 'Scripts Externos',
        component: () => import('@/views/externalScripts/ExternalScripts'),
      },
      {
        path: '/scripts-externos/criar',
        name: 'Criar Script Externo',
        component: () => import('@/views/externalScripts/CreateExternalScript'),
      },
      {
        path: '/scripts-externos/:scriptId',
        name: 'Editar Script Externo',
        component: () => import('@/views/externalScripts/EditExternalScript'),
      },
      {
        path: '/newsletters',
        name: 'Newsletters',
        component: () => import('@/views/newsletters/Newsletters'),
      },
      {
        path: '/amelia-pascoa',
        name: 'Amélia Pascoa',
        component: () => import('@/views/ameliaPascoa/AmeliaPascoa'),
      },
      {
        path: '/sac',
        name: 'SAC',
        component: () => import('@/views/sacs/Sacs'),
      },
      {
        path: '/submarcas',
        name: 'Submarcas',
        component: () => import('@/views/brandSections/BrandSections'),
      },
      {
        path: '/submarcas/criar',
        name: 'Criar Submarca',
        component: () => import('@/views/brandSections/CreateBrandSection'),
      },
      {
        path: '/submarcas/:brandSectionId',
        name: 'Editar Submarca',
        component: () => import('@/views/brandSections/EditBrandSection'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/login',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: '/recuperar-senha',
        name: 'Recuperar Senha',
        component: () => import('@/views/pages/ResetPassword'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(), //process.env.BASE_URL
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router

import axios from 'axios'

const sacModule = {
  namespaced: true,
  state() {
    return {
      allSacs: [],
      error: null,
      lastSac: {},
      apiUrl: '/api/sacs',
      downloadData: '',
    }
  },
  getters: {
    allSacs(state) {
      return state.allSacs
    },
    error: (state) => {
      return state.error
    },
    lastSac: (state) => {
      return state.lastSac
    },
    downloadData: (state) => {
      return state.downloadData
    },
  },
  mutations: {
    allSacs: (state, sacs) => {
      state.allSacs = sacs
    },
    error: (state, message) => {
      state.error = message
    },
    lastSac: (state, sac) => {
      state.lastSac = sac
    },
    downloadData: (state, file) => {
      state.downloadData = file
    },
  },
  actions: {
    async fetchSacs({ state, commit, rootState }) {
      commit('error', null)
      commit('allSacs', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allSacs', response.data.sacs)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allSacs', [])
        })
    },
    async storeSac({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getSac({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastSac', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastSac', response.data.sac)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastSac', {})
        })
    },
    async updateSac({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteSac({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async downloadAll({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl + '/api-download', {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
          responseType: 'blob',
        })
        .then((response) => {
          commit('error', null)
          commit('downloadData', response.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('downloadData', {})
        })
    },
  },
}

export default sacModule

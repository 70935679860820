import axios from 'axios'

const releaseInformationModule = {
  namespaced: true,
  state() {
    return {
      allReleaseInformation: [],
      error: null,
      lastReleaseInformation: {},
      apiUrl: '/api/releases-information',
    }
  },
  getters: {
    allReleaseInformation(state) {
      return state.allReleaseInformation
    },
    error: (state) => {
      return state.error
    },
    lastReleaseInformation: (state) => {
      return state.lastReleaseInformation
    },
  },
  mutations: {
    allReleaseInformation: (state, releasesInformation) => {
      state.allReleaseInformation = releasesInformation
    },
    error: (state, message) => {
      state.error = message
    },
    lastReleaseInformation: (state, releasesInformation) => {
      state.lastReleaseInformation = releasesInformation
    },
  },
  actions: {
    async fetchReleaseInformation({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allReleaseInformation', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allReleaseInformation', [])
        })
    },
    async storeReleaseInformation({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getReleaseInformation({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastReleaseInformation', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastReleaseInformation', {})
        })
    },
    async updateReleaseInformation({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteReleaseInformation({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default releaseInformationModule

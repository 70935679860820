<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand></CSidebarBrand>
    <CSidebarNav>
      <CNavItem href="/usuarios">
        <CIcon customClassName="nav-icon" icon="cilPeople" />
        Usuários
      </CNavItem>
      <CNavItem href="/marcas">
        <CIcon customClassName="nav-icon" icon="cilBadge" />
        Marcas
      </CNavItem>
      <CNavItem href="/submarcas">
        <CIcon customClassName="nav-icon" icon="cilBadge" />
        Submarcas
      </CNavItem>
      <CNavItem href="/categorias-de-produto">
        <CIcon customClassName="nav-icon" icon="cilListRich" />
        Categorias de produto
      </CNavItem>
      <CNavItem href="/subcategorias-de-produto">
        <CIcon customClassName="nav-icon" icon="cilListRich" />
        Subcategorias de produto
      </CNavItem>
      <CNavItem href="/produtos">
        <CIcon customClassName="nav-icon" icon="cilBurger" />
        Produtos
      </CNavItem>
      <!-- <CNavItem href="/variantes">
        <CIcon customClassName="nav-icon" icon="cilBurger" />
        Variantes de Produtos
      </CNavItem> -->
      <CNavItem href="/receitas">
        <CIcon customClassName="nav-icon" icon="cilDinner" />
        Receitas
      </CNavItem>
      <CNavItem href="/releases">
        <CIcon customClassName="nav-icon" icon="cilNotes" />
        Releases
      </CNavItem>
      <CNavItem href="/release-informacoes">
        <CIcon customClassName="nav-icon" icon="cilNewspaper" />
        Release informações
      </CNavItem>
      <CNavItem href="/noticias">
        <CIcon customClassName="nav-icon" icon="cilNewspaper" />
        Notícias
      </CNavItem>
      <CNavItem href="/banners">
        <CIcon customClassName="nav-icon" icon="cilWallpaper" />
        Banners
      </CNavItem>
      <CNavItem href="/area-kids">
        <CIcon customClassName="nav-icon" icon="cilChild" />
        Área Kids
      </CNavItem>
      <CNavItem href="/codigos-etica">
        <CIcon customClassName="nav-icon" icon="cilInstitution" />
        Códigos de Ética
      </CNavItem>
      <CNavItem href="/demonstracoes-financeiras">
        <CIcon customClassName="nav-icon" icon="cilMoney" />
        Demonstrações Financeiras
      </CNavItem>
      <CNavItem href="/vagas">
        <CIcon customClassName="nav-icon" icon="cilWeightlifitng" />
        Vagas
      </CNavItem>
      <CNavItem href="/catalogos-produtos">
        <CIcon customClassName="nav-icon" icon="cilSpreadsheet" />
        Catálogos de Produtos
      </CNavItem>
      <CNavItem href="/scripts-externos">
        <CIcon customClassName="nav-icon" icon="cilSpreadsheet" />
        Scripts Externos
      </CNavItem>
      <CNavItem href="/newsletters">
        <CIcon customClassName="nav-icon" icon="cilSpreadsheet" />
        Newsletters
      </CNavItem>
      <CNavItem href="/sac">
        <CIcon customClassName="nav-icon" icon="cilSpreadsheet" />
        SAC
      </CNavItem>
      <CNavItem href="/amelia-pascoa">
        <CIcon customClassName="nav-icon" icon="cilSpreadsheet" />
        Amelia Pascoa
      </CNavItem>
    </CSidebarNav>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'AppSidebar',
  components: {},
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

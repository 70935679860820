import axios from 'axios'

const ameliaPascoaModule = {
  namespaced: true,
  state() {
    return {
      allAmeliaPascoa: [],
      error: null,
      lastAmeliaPascoa: {},
      apiUrl: '/api/amelia-pascoa',
      downloadData: {},
    }
  },
  getters: {
    allAmeliaPascoa(state) {
      return state.allAmeliaPascoa
    },
    error: (state) => {
      return state.error
    },
    lastAmeliaPascoa: (state) => {
      return state.lastAmeliaPascoa
    },
    downloadData: (state) => {
      return state.downloadData
    },
  },
  mutations: {
    allAmeliaPascoa: (state, amelia_pascoa) => {
      state.allAmeliaPascoa = amelia_pascoa
    },
    error: (state, message) => {
      state.error = message
    },
    lastAmeliaPascoa: (state, ameliaPascoa) => {
      state.lastAmeliaPascoa = ameliaPascoa
    },
    downloadData: (state, file) => {
      state.downloadData = file
    },
  },
  actions: {
    async fetchAmeliaPascoa({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allAmeliaPascoa', response.data.amelia_pascoa)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allAmeliaPascoa', [])
        })
    },
    async storeAmeliaPascoa({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getAmeliaPascoa({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastAmeliaPascoa', response.data.ameliaPascoa)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastAmeliaPascoa', {})
        })
    },
    async updateAmeliaPascoa({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteAmeliaPascoa({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async downloadAll({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl + '/api-download', {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
          responseType: 'blob',
        })
        .then((response) => {
          commit('error', null)
          commit('downloadData', response.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('downloadData', {})
        })
    },
  },
}

export default ameliaPascoaModule

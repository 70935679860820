import axios from 'axios'

const passwordResetModule = {
  namespaced: true,
  state() {
    return {
      error: null,
      success: null,
      lastReset: {},
      apiUrl: '/api/password-reset',
    }
  },
  getters: {
    error: (state) => {
      return state.error
    },
    success: (state) => {
      return state.success
    },
    lastReset: (state) => {
      return state.lastReset
    },
  },
  mutations: {
    error: (state, message) => {
      state.error = message
    },
    success: (state, message) => {
      state.success = message
    },
    lastReset: (state, reset) => {
      state.lastReset = reset
    },
  },
  actions: {
    async storeReset({ state, commit }, formData) {
      commit('error', null)
      commit('success', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          commit('error', null)
          commit('success', response.data.message)
        })
        .catch((error) => {
          commit('success', null)
          commit('error', error.response.data.message)
        })
    },
    async updateReset({ state, commit }, formData) {
      commit('error', null)
      commit('success', null)
      await axios
        .post(state.apiUrl + '/' + formData.token, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          commit('error', null)
          commit('success', response.data.message)
        })
        .catch((error) => {
          commit('success', null)
          commit('error', error.response.data.message)
        })
    },
  },
}

export default passwordResetModule

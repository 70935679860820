import axios from 'axios'

const packageModule = {
  namespaced: true,
  state() {
    return {
      allPackages: [],
      error: null,
      lastPackage: {},
      apiUrl: '/api/packages',
    }
  },
  getters: {
    allPackages(state) {
      return state.allPackages
    },
    error: (state) => {
      return state.error
    },
    lastPackage: (state) => {
      return state.lastPackage
    },
  },
  mutations: {
    allPackages: (state, packages) => {
      state.allPackages = packages
    },
    error: (state, message) => {
      state.error = message
    },
    lastPackage: (state, singlepackage) => {
      state.lastPackage = singlepackage
    },
  },
  actions: {
    async fetchPackages({ state, commit, rootState }) {
      commit('error', null)
      commit('allPackages', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allPackages', response.data.packages)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allPackages', [])
        })
    },
    async storePackage({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getPackage({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastPackage', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastPackage', response.data.package)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastPackage', {})
        })
    },
    async updatePackage({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deletePackage({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default packageModule

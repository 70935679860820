import axios from 'axios'

const productCategoryModule = {
  namespaced: true,
  state() {
    return {
      allCategories: [],
      error: null,
      lastCategory: {},
      apiUrl: '/api/product-categories',
    }
  },
  getters: {
    allCategories(state) {
      return state.allCategories
    },
    error: (state) => {
      return state.error
    },
    lastCategory: (state) => {
      return state.lastCategory
    },
  },
  mutations: {
    allCategories: (state, categories) => {
      state.allCategories = categories
    },
    error: (state, message) => {
      state.error = message
    },
    lastCategory: (state, category) => {
      state.lastCategory = category
    },
  },
  actions: {
    async fetchCategories({ state, commit, rootState }) {
      commit('error', null)
      commit('allCategories', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allCategories', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allCategories', [])
        })
    },
    async storeCategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getCategory({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastCategory', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastCategory', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastCategory', {})
        })
    },
    async updateCategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteCategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productCategoryModule

import axios from 'axios'

const nutritionalInfoModule = {
  namespaced: true,
  state() {
    return {
      allNutritionalInfo: [],
      errorInfo: null,
      lastNutritionalInfo: {},
      apiUrl: '/api/nutritional-infos',
    }
  },
  getters: {
    allNutritionalInfo(state) {
      return state.allNutritionalInfo
    },
    errorInfo: (state) => {
      return state.errorInfo
    },
    lastNutritionalInfo: (state) => {
      return state.lastNutritionalInfo
    },
  },
  mutations: {
    allNutritionalInfo: (state, models) => {
      state.allNutritionalInfo = models
    },
    errorInfo: (state, message) => {
      state.errorInfo = message
    },
    lastNutritionalInfo: (state, model) => {
      state.lastNutritionalInfo = model
    },
  },
  actions: {
    async fetchNutritionalInfo({ state, commit, rootState }) {
      commit('allNutritionalInfo', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('errorInfo', null)
          commit('allNutritionalInfo', response.data.data)
        })
        .catch((error) => {
          commit('errorInfo', error.response.data.error_description)
          commit('allNutritionalInfo', [])
        })
    },
    async storeNutritionalInfo({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('errorInfo', null)
        })
        .catch((error) => {
          commit('errorInfo', error.response.data.message)
        })
    },
    async getNutritionalInfo({ state, commit, rootState }, itemId) {
      commit('lastNutritionalInfo', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('errorInfo', null)
          commit('lastNutritionalInfo', response.data.data)
        })
        .catch((error) => {
          commit('errorInfo', error.response.data.error_description)
          commit('lastNutritionalInfo', {})
        })
    },
    async updateNutritionalInfo({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.id, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('errorInfo', null)
        })
        .catch((error) => {
          commit('errorInfo', error.response.data.message)
        })
    },
    async deleteNutritionalInfo({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('errorInfo', null)
        })
        .catch((error) => {
          commit('errorInfo', error.response.data.message)
        })
    },
  },
}

export default nutritionalInfoModule

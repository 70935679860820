import axios from 'axios'

const releaseModule = {
  namespaced: true,
  state() {
    return {
      allReleases: [],
      error: null,
      lastRelease: {},
      apiUrl: '/api/releases',
    }
  },
  getters: {
    allReleases(state) {
      return state.allReleases
    },
    error: (state) => {
      return state.error
    },
    lastRelease: (state) => {
      return state.lastRelease
    },
  },
  mutations: {
    allReleases: (state, releases) => {
      state.allReleases = releases
    },
    error: (state, message) => {
      state.error = message
    },
    lastRelease: (state, release) => {
      state.lastRelease = release
    },
  },
  actions: {
    async fetchReleases({ state, commit, rootState }) {
      commit('error', null)
      commit('allReleases', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allReleases', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allReleases', [])
        })
    },
    async storeRelease({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getRelease({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastRelease', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastRelease', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastRelease', {})
        })
    },
    async updateRelease({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteRelease({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default releaseModule

import axios from 'axios'

const recipeCategoryModule = {
  namespaced: true,
  state() {
    return {
      allCategories: [],
      error: null,
      lastCategory: null,
      apiUrl: '/api/recipe-categories',
    }
  },
  getters: {
    allCategories(state) {
      return state.allCategories
    },
    error: (state) => {
      return state.error
    },
    lastCategory: (state) => {
      return state.lastCategory
    },
  },
  mutations: {
    allCategories: (state, categories) => {
      state.allCategories = categories
    },
    error: (state, message) => {
      state.error = message
    },
    lastCategory: (state, category) => {
      state.lastCategory = category
    },
  },
  actions: {
    async fetchCategories({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allCategories', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allCategories', [])
        })
    },
  },
}

export default recipeCategoryModule

import axios from 'axios'

const productCatalogModule = {
  namespaced: true,
  state() {
    return {
      allCatalogs: [],
      error: null,
      lastCatalog: {},
      apiUrl: '/api/product-catalogs',
    }
  },
  getters: {
    allCatalogs(state) {
      return state.allCatalogs
    },
    error: (state) => {
      return state.error
    },
    lastCatalog: (state) => {
      return state.lastCatalog
    },
  },
  mutations: {
    allCatalogs: (state, catalogs) => {
      state.allCatalogs = catalogs
    },
    error: (state, message) => {
      state.error = message
    },
    lastCatalog: (state, catalog) => {
      state.lastCatalog = catalog
    },
  },
  actions: {
    async fetchCatalogs({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allCatalogs', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allCatalogs', [])
        })
    },
    async storeCatalog({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getCatalog({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastCatalog', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastCatalog', {})
        })
    },
    async updateCatalog({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteCatalog({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productCatalogModule

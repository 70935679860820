import axios from 'axios'

const ethicCodeModule = {
  namespaced: true,
  state() {
    return {
      allCodes: [],
      error: null,
      lastCode: {},
      apiUrl: '/api/ethic-codes',
    }
  },
  getters: {
    allCodes(state) {
      return state.allCodes
    },
    error: (state) => {
      return state.error
    },
    lastCode: (state) => {
      return state.lastCode
    },
  },
  mutations: {
    allCodes: (state, codes) => {
      state.allCodes = codes
    },
    error: (state, message) => {
      state.error = message
    },
    lastCode: (state, code) => {
      state.lastCode = code
    },
  },
  actions: {
    async fetchCodes({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allCodes', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allCodes', [])
        })
    },
    async storeCode({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getCode({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastCode', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastCode', {})
        })
    },
    async updateCode({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteCode({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default ethicCodeModule

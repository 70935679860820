import axios from 'axios'

const productSubcategoryModule = {
  namespaced: true,
  state() {
    return {
      allSubcategories: [],
      error: null,
      lastSubcategory: {},
      apiUrl: '/api/product-subcategories',
    }
  },
  getters: {
    allSubcategories(state) {
      return state.allSubcategories
    },
    error: (state) => {
      return state.error
    },
    lastSubcategory: (state) => {
      return state.lastSubcategory
    },
  },
  mutations: {
    allSubcategories: (state, subcategories) => {
      state.allSubcategories = subcategories
    },
    error: (state, message) => {
      state.error = message
    },
    lastSubcategory: (state, subcategory) => {
      state.lastSubcategory = subcategory
    },
  },
  actions: {
    async fetchSubcategories({ state, commit, rootState }) {
      commit('error', null)
      commit('allSubcategories', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allSubcategories', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allSubcategories', [])
        })
    },
    async storeSubcategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getSubcategory({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastSubcategory', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastSubcategory', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastSubcategory', {})
        })
    },
    async updateSubcategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteSubcategory({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productSubcategoryModule

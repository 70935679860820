import axios from 'axios'

const brandModule = {
  namespaced: true,
  state() {
    return {
      allBrands: [],
      error: null,
      lastBrand: null,
      apiUrl: '/api/brands',
    }
  },
  getters: {
    allBrands(state) {
      return state.allBrands
    },
    error: (state) => {
      return state.error
    },
    lastBrand: (state) => {
      return state.lastBrand
    },
  },
  mutations: {
    allBrands: (state, brands) => {
      state.allBrands = brands
    },
    error: (state, message) => {
      state.error = message
    },
    lastBrand: (state, brand) => {
      state.lastBrand = brand
    },
  },
  actions: {
    async fetchBrands({ state, commit, rootState }) {
      commit('error', null)
      commit('allBrands', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allBrands', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allBrands', [])
        })
    },
    async storeBrand({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getBrand({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastBrand', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastBrand', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastBrand', {})
        })
    },
    async updateBrand({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteBrand({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default brandModule

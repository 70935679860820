import axios from 'axios'

const productModule = {
  namespaced: true,
  state() {
    return {
      allProducts: [],
      error: null,
      lastProduct: {},
      apiUrl: '/api/products',
    }
  },
  getters: {
    allProducts(state) {
      return state.allProducts
    },
    error: (state) => {
      return state.error
    },
    lastProduct: (state) => {
      return state.lastProduct
    },
  },
  mutations: {
    allProducts: (state, products) => {
      state.allProducts = products
    },
    error: (state, message) => {
      state.error = message
    },
    lastProduct: (state, product) => {
      state.lastProduct = product
    },
  },
  actions: {
    async fetchProducts({ state, commit, rootState }, query = '') {
      commit('error', null)
      commit('allProducts', [])
      await axios
        .get(state.apiUrl + query, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allProducts', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allProducts', [])
        })
    },
    async storeProduct({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getProduct({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastProduct', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastProduct', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastProduct', {})
        })
    },
    async updateProduct({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteProduct({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productModule

import axios from 'axios'

const financialStatementModule = {
  namespaced: true,
  state() {
    return {
      allStatements: [],
      error: null,
      lastStatement: {},
      apiUrl: '/api/financial-statements',
    }
  },
  getters: {
    allStatements(state) {
      return state.allStatements
    },
    error: (state) => {
      return state.error
    },
    lastStatement: (state) => {
      return state.lastStatement
    },
  },
  mutations: {
    allStatements: (state, statements) => {
      state.allStatements = statements
    },
    error: (state, message) => {
      state.error = message
    },
    lastStatement: (state, statement) => {
      state.lastStatement = statement
    },
  },
  actions: {
    async fetchStatements({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allStatements', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allStatements', [])
        })
    },
    async storeStatement({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getStatement({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastStatement', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastStatement', {})
        })
    },
    async updateStatement({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteStatement({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default financialStatementModule

import axios from 'axios'

const newsModule = {
  namespaced: true,
  state() {
    return {
      allNews: [],
      error: null,
      lastNew: {},
      apiUrl: '/api/news',
    }
  },
  getters: {
    allNews(state) {
      return state.allNews
    },
    error: (state) => {
      return state.error
    },
    lastNew: (state) => {
      return state.lastNew
    },
  },
  mutations: {
    allNews: (state, news) => {
      state.allNews = news
    },
    error: (state, message) => {
      state.error = message
    },
    lastNew: (state, news) => {
      state.lastNew = news
    },
  },
  actions: {
    async fetchNews({ state, commit, rootState }) {
      commit('error', null)
      commit('allNews', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allNews', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allNews', [])
        })
    },
    async storeNew({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getNew({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastNew', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastNew', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastNew', {})
        })
    },
    async updateNew({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteNew({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default newsModule

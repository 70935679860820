import axios from 'axios'

const languageModule = {
  namespaced: true,
  state() {
    return {
      allLanguages: [],
      error: null,
      lastLanguage: null,
      apiUrl: '/api/languages',
    }
  },
  getters: {
    allLanguages(state) {
      return state.allLanguages
    },
    error: (state) => {
      return state.error
    },
    lastLanguage: (state) => {
      return state.lastLanguage
    },
  },
  mutations: {
    allLanguages: (state, languages) => {
      state.allLanguages = languages
    },
    error: (state, message) => {
      state.error = message
    },
    lastLanguage: (state, language) => {
      state.lastLanguage = language
    },
  },
  actions: {
    async fetchLanguages({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allLanguages', response.data.languages)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allLanguages', [])
        })
    },
  },
}

export default languageModule

import axios from 'axios'

const recipeModule = {
  namespaced: true,
  state() {
    return {
      allRecipes: [],
      error: null,
      lastRecipe: {},
      apiUrl: '/api/recipes',
    }
  },
  getters: {
    allRecipes(state) {
      return state.allRecipes
    },
    error: (state) => {
      return state.error
    },
    lastRecipe: (state) => {
      return state.lastRecipe
    },
  },
  mutations: {
    allRecipes: (state, recipes) => {
      state.allRecipes = recipes
    },
    error: (state, message) => {
      state.error = message
    },
    lastRecipe: (state, recipe) => {
      state.lastRecipe = recipe
    },
  },
  actions: {
    async fetchRecipes({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allRecipes', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allRecipes', [])
        })
    },
    async storeRecipe({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getRecipe({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastRecipe', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastRecipe', {})
        })
    },
    async updateRecipe({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteRecipe({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default recipeModule

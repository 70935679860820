import axios from 'axios'

const sectionModule = {
  namespaced: true,
  state() {
    return {
      allSections: [],
      error: null,
      lastSection: null,
      apiUrl: '/api/sections',
    }
  },
  getters: {
    allSections(state) {
      return state.allSections
    },
    error: (state) => {
      return state.error
    },
    lastSection: (state) => {
      return state.lastSection
    },
  },
  mutations: {
    allSections: (state, sections) => {
      state.allSections = sections
    },
    error: (state, message) => {
      state.error = message
    },
    lastSection: (state, section) => {
      state.lastSection = section
    },
  },
  actions: {
    async fetchSections({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allSections', response.data.sections)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allSections', [])
        })
    },
    async storeSection({ state, commit, rootState }, sectionData) {
      await axios
        .post(state.apiUrl, sectionData, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastSection', response.data.section)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
          commit('lastSection', null)
        })
    },
    async updateSection({ state, commit, rootState }, sectionData) {
      await axios
        .post(state.apiUrl + '/' + sectionData.id, sectionData, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastSection', response.data.section)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
          commit('lastSection', null)
        })
    },
    async deleteSection({ state, commit, rootState }, sectionData) {
      await axios
        .delete(state.apiUrl + '/' + sectionData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastSection', response.data.section)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
          commit('lastSection', null)
        })
    },
  },
}

export default sectionModule

<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle
      placement="bottom-end"
      class="py-0"
      :caret="false"
      href="javascript:void(0)"
    >
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem @click="hadleLogout" class="mt-2">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/vigor-avatar.png'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['doLogout']),
    async hadleLogout() {
      await this.doLogout()
      if (!this.loginError) {
        this.$router.push('/login')
      }
    },
  },
  computed: {
    ...mapGetters(['loginError']),
  },
}
</script>

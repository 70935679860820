import axios from 'axios'

const productBlockModule = {
  namespaced: true,
  state() {
    return {
      allBlocks: [],
      error: null,
      lastBlock: {},
      apiUrl: '/api/product-blocks',
    }
  },
  getters: {
    allBlocks(state) {
      return state.allBlocks
    },
    error: (state) => {
      return state.error
    },
    lastBlock: (state) => {
      return state.lastBlock
    },
  },
  mutations: {
    allBlocks: (state, blocks) => {
      state.allBlocks = blocks
    },
    error: (state, message) => {
      state.error = message
    },
    lastBlock: (state, block) => {
      state.lastBlock = block
    },
  },
  actions: {
    async fetchBlocks({ state, commit, rootState }, query = '') {
      commit('error', null)
      commit('allBlocks', [])
      await axios
        .get(state.apiUrl + query, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allBlocks', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allBlocks', [])
        })
    },
    async storeBlock({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getBlock({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastBlock', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastBlock', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastBlock', {})
        })
    },
    async updateBlock({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteBlock({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productBlockModule

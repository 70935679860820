import axios from 'axios'

const brandSectionModule = {
  namespaced: true,
  state() {
    return {
      allBrandSections: [],
      error: null,
      lastBrandSection: {},
      apiUrl: '/api/brand-sections',
    }
  },
  getters: {
    allBrandSections(state) {
      return state.allBrandSections
    },
    error: (state) => {
      return state.error
    },
    lastBrandSection: (state) => {
      return state.lastBrandSection
    },
  },
  mutations: {
    allBrandSections: (state, models) => {
      state.allBrandSections = models
    },
    error: (state, message) => {
      state.error = message
    },
    lastBrandSection: (state, model) => {
      state.lastBrandSection = model
    },
  },
  actions: {
    async fetchBrandSections({ state, commit, rootState }) {
      commit('allBrandSections', [])
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allBrandSections', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allBrandSections', [])
        })
    },
    async storeBrandSection({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getBrandSection({ state, commit, rootState }, itemId) {
      commit('lastBrandSection', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastBrandSection', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastBrandSection', {})
        })
    },
    async updateBrandSection({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteBrandSection({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default brandSectionModule

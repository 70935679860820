import axios from 'axios'

const newsletterModule = {
  namespaced: true,
  state() {
    return {
      allNewsletters: [],
      error: null,
      lastNewsletter: {},
      apiUrl: '/api/newsletters',
      downloadData: {},
    }
  },
  getters: {
    allNewsletters(state) {
      return state.allNewsletters
    },
    error: (state) => {
      return state.error
    },
    lastNewsletter: (state) => {
      return state.lastNewsletter
    },
    downloadData: (state) => {
      return state.downloadData
    },
  },
  mutations: {
    allNewsletters: (state, newsletters) => {
      state.allNewsletters = newsletters
    },
    error: (state, message) => {
      state.error = message
    },
    lastNewsletter: (state, newsletter) => {
      state.lastNewsletter = newsletter
    },
    downloadData: (state, file) => {
      state.downloadData = file
    },
  },
  actions: {
    async fetchNewsletters({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allNewsletters', response.data.newsletters)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allNewsletters', [])
        })
    },
    async storeNewsletter({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getNewsletter({ state, commit, rootState }, itemId) {
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastNewsletter', response.data.newsletter)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastNewsletter', {})
        })
    },
    async updateNewsletter({ state, commit, rootState }, formData) {
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteNewsletter({ state, commit, rootState }, formData) {
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async downloadAll({ state, commit, rootState }) {
      await axios
        .get(state.apiUrl + '/api-download', {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
          responseType: 'blob',
        })
        .then((response) => {
          commit('error', null)
          commit('downloadData', response.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('downloadData', {})
        })
    },
  },
}

export default newsletterModule

import axios from 'axios'

const productVariantModule = {
  namespaced: true,
  state() {
    return {
      allVariants: [],
      error: null,
      lastVariant: {},
      apiUrl: '/api/product-variants',
    }
  },
  getters: {
    allVariants(state) {
      return state.allVariants
    },
    error: (state) => {
      return state.error
    },
    lastVariant: (state) => {
      return state.lastVariant
    },
  },
  mutations: {
    allVariants: (state, variants) => {
      state.allVariants = variants
    },
    error: (state, message) => {
      state.error = message
    },
    lastVariant: (state, variant) => {
      state.lastVariant = variant
    },
  },
  actions: {
    async fetchVariants({ state, commit, rootState }, query = '') {
      commit('error', null)
      commit('allVariants', [])
      await axios
        .get(state.apiUrl + query, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('allVariants', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('allVariants', [])
        })
    },
    async storeVariant({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async getVariant({ state, commit, rootState }, itemId) {
      commit('error', null)
      commit('lastVariant', {})
      await axios
        .get(state.apiUrl + '/' + itemId, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then((response) => {
          commit('error', null)
          commit('lastVariant', response.data.data)
        })
        .catch((error) => {
          commit('error', error.response.data.error_description)
          commit('lastVariant', {})
        })
    },
    async updateVariant({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .post(state.apiUrl + '/' + formData.get('id'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
    async deleteVariant({ state, commit, rootState }, formData) {
      commit('error', null)
      await axios
        .delete(state.apiUrl + '/' + formData.id, {
          headers: {
            Authorization: 'Bearer ' + rootState.accessToken,
          },
        })
        .then(() => {
          commit('error', null)
        })
        .catch((error) => {
          commit('error', error.response.data.message)
        })
    },
  },
}

export default productVariantModule
